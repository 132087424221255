exports.components = {
  "component---src-components-book-components-book-page-js": () => import("./../../../src/components/bookComponents/bookPage.js" /* webpackChunkName: "component---src-components-book-components-book-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-footer-about-us-js": () => import("./../../../src/pages/footer/about-us.js" /* webpackChunkName: "component---src-pages-footer-about-us-js" */),
  "component---src-pages-footer-contact-js": () => import("./../../../src/pages/footer/contact.js" /* webpackChunkName: "component---src-pages-footer-contact-js" */),
  "component---src-pages-footer-privacy-js": () => import("./../../../src/pages/footer/privacy.js" /* webpackChunkName: "component---src-pages-footer-privacy-js" */),
  "component---src-pages-footer-terms-js": () => import("./../../../src/pages/footer/terms.js" /* webpackChunkName: "component---src-pages-footer-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-topics-airtable-data-topics-js": () => import("./../../../src/pages/topics/{airtable.data__Topics}.js" /* webpackChunkName: "component---src-pages-topics-airtable-data-topics-js" */),
  "component---src-pages-topics-index-js": () => import("./../../../src/pages/topics/index.js" /* webpackChunkName: "component---src-pages-topics-index-js" */)
}

